<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100" data-cmp="ExtensionsMain">
        <AppHeader></AppHeader>
        
        <section v-if="logged" class="flex-fill container-fluid px-0 ">
            <div class="row g-0 mx-0">
                <div class="col-12 pb-4">
                    <h1 class="pt-5 text-center fw-light">Outlet.<span class="text-success">js</span></h1>
                </div>


                <div class="col-12 col-md-8 mx-auto">
                    <!-- <div>
                        <ul class="nav nav-tabs justify-content-center">
                            <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
                                <a class="nav-link" href="#"
                                    :class="[tab.name == current.tab? 'active': '']"
                                    @click.prevent="section(tab)"
                                    >{{tab.label}}</a>
                            </li>
                        </ul>
                    </div> -->

                    <div class="text-center pb-2">
                        <button @click.prevent="add" class="btn btn-outline-success me-2">
                            <span class="material-icons pull-left">
                            launch
                            </span>
                            <span class="ms-2">Add Extension</span>
                        </button>
                        <button @click.prevent="register" class="btn btn-outline-dark me-2">Register Extension</button>
                        <button @click.prevent="update" class="btn btn-outline-dark me-2">Update Extension</button>
                        <button @click.prevent="unregister" class="btn btn-outline-danger me-2">
                            <span class="material-icons pull-left">
                            delete_outline
                            </span>
                        </button>
                    </div>

                    <div class="text-center pb-2">
                        <button @click.prevent="select" class="btn btn-outline-primary me-2">Open Extension</button>
                        <button @click.prevent="openLast" class="btn btn-outline-primary me-2">Open Last</button>
                    </div>




                </div>
            </div>
            <div class="row mt-4">
                <div class="col-6 d-flex justify-content-end">
                    <div class="w-50 text-center">
                        <button @click.prevent="selectPhoto" class="btn btn-primary me-2">Select Photo</button>
                        <div class="mt-3 ratio ratio-1x1">
                            <img class="img-fluid w-100 selectable-image card shadow rounded-3" :src="current.imageURL"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-start">
                    <div class="w-50 text-center">
                        <button @click.prevent="selectText" class="btn btn-primary me-2">Select Text</button>
                        <div class="mt-3 ratio ratio-1x1">
                            <textarea ref="editableText" class="p-3 card w-100 shadow rounded-3" style="width: 320px; height: 320px">The Secret of Life reveals the lens through which all decisions can be made for the fullest life expression and experience! By combining ancient wisdom with modern science, we now can design a life which supports our design, our energy, our health and our life experience! Learn how to put on your “Secret Formula Glasses” to see the world in a whole new way and receive all the benefits that come with it!</textarea>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section v-else class="flex-fill container-fluid px-0 ">
            <div class="row g-0 mx-0">
                <div class="col-12 pb-4">
                    <h1 class="pt-5 text-center fw-light">Outlet.<span class="text-success">js</span></h1>
                </div>
                <div class="col-12 pb-4">
                    <p class="text-center rounded-circle border mx-auto pt-2" style="width: 44px; height: 44px">
                        <span class="material-icons-outlined text-muted">
                        lock
                        </span>
                    </p>
                    <p class="text-center mx-auto" style="width: 240px">
                        <input @keyup.enter="getAccess" ref="pass" class="form-control text-center fs-4" type="password" v-model="outletPass"/>
                    </p>
                </div>
            </div>
        </section>

        <AppFooter class="mt-5"></AppFooter>

        <!-- <UserDetails ref="userDetails" :user="current.user"></UserDetails> -->

    </div>
</template>

<script>

// import Data from "./Data.vue";
// import UserDetails from "./UserDetails.vue";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
// import plugin from '../../plugins/outlet/plugin';


export default {
    name: 'Extensions',

    components: {
        // Data,
        // UserDetails,
        AppHeader,
        AppFooter
    },

    data() {
        return {
            users: [],
            tabs: [
                { label: 'Users', name: 'users'},
                { label: 'Opportunities', name: 'opportunities'},
                { label: 'Strategies', name: 'strategies'},
                { label: 'Companies', name: 'companies'},
                { label: 'Data', name: 'data'},
            ],
            panel: null,
            current: {
                user: null,
                tab: null, 
                imageURL: 'https://dummyimage.com/400x400/ffffff/dddddd?text=?'
            }, 
            outletPass: ''
        }
    },

    computed: {
        marketplaceURL() {
            if(/localhost/.test(document.location.hostname)) {
                return 'http://localhost:7777/marketplace/';
            } else {
                return this.$outlet.marketplaceURL;
            }
        }, 

        logged() {
            if(sessionStorage?.allowOutlet && sessionStorage.allowOutlet == btoa('outletjs')) {
                return true;
            } else {
                return false;
            }
        }
    },
    
    methods: {
        getAccess() {
            if(btoa(this.$refs.pass.value)  == btoa('outletjs')) {
                sessionStorage.allowOutlet = btoa(this.$refs.pass.value);
                document.location.reload();
            } else {
                this.$outlet.notify('ERROR: Invalid password')
            }
        },

        section(tab) {
            this.current.tab = tab.name;
            this.$router.push({ path : `/extensions/${tab.name}`});
        }, 


        /**
         * 
         * 
         * 
         */
        add() {
            /** overwrite marketplaceURL to allow running from localhost:7777 */
            this.$outlet.marketplaceURL = this.marketplaceURL;

			this.$outlet?.marketplace(plugin => {
                this.$root.$outlet.notify({
                    type: 'confirm',
                    message: `Are you sure you want to install the extension <b>${plugin.title}</b>?`,
                    callback: (okay) =>{
                        if(okay) {
                            this.$outlet.register(plugin);
                        }
                    }
                });

                console.log('Marketplace > Add', plugin);
            });
        },


        register() {
			this.$root?.$outlet.register();
        },

        unregister() {
			this.$root?.$outlet.unregister();
        },

		async select() {
			this.$root?.$outlet.select( async (plugin) => {
                let bridge = await this.$root?.$outlet.open(plugin);
                    /** 
                     * handle update (via push) 
                     * the Plugin component will call update in the extension IFrame
                     * and the reply can be capture by "bridge.reply.update"
                     */
                    bridge.$events.bind('bridge.reply.update', (e, data)=> {
                        e;
                        console.log(data);
                        this.$root?.$outlet.hide();
                    }); 

                bridge.send('data', ['Something about the fox jumping']);

                (window.location.hostname == 'localhost') && (window.bridge = bridge);
            });
		},


		update() {
			this.$root?.$outlet.update();
		},
		

		showPlugin(plugin) {
			this.$root?.$outlet.open(plugin);
		},
        

        sendTo(data, callback) {
			this.$root?.$outlet.sendTo(data, callback);
		},

        openLast() {
            this.$root?.$outlet.last();
        },

        selectPhoto() {
			this.$root?.$outlet.sendTo(null, (data)=> {
                this.current.imageURL = data.src.medium;
                this.$root.$outlet.hide();
                console.log('RETURNED DATA', data);
            });
        },

        selectText() {
            let data = this.$refs.editableText.value;


			this.$root?.$outlet.sendTo(data, (response)=> {
                this.$refs.editableText.value = response;
                this.$root.$outlet.hide();
                console.log('RETURNED DATA', response);
            });
        }

    },


    async mounted() {
        // this.users = await this.$store.dispatch('fetchUsers');
        // this.current.tab = this.$route.params?.section
        // this.setPanel();
        window.view = this;
    },
}
</script>
<style lang="css" scoped>
    .selectable-image {
        object-fit: cover;
        width: 320px;
        height: 320px;
    }
</style>